<template>
  <div class="cars mr-2">
    <v-card width="370" height="390" hover>
      <v-card-title class="carTitle">{{ car.model }}</v-card-title>
      <v-card-subtitle class="text-start subTitle">
        {{ minPriceText }} ₽
        <!-- <v-spacer></v-spacer> -->
        <v-chip small :color="chipColor">
          {{ car.status.name }} {{ car.status.date }}</v-chip
        >
      </v-card-subtitle>
      <div>
        <v-img
          @click="goToCarPage()"
          :src="car.imgLink"
          max-width="370"
          height="250"
          ><div
            v-if="car.transmission == 'Механика'"
            style="position: absolute; right: 0.4rem; top: 0.2rem"
          >
            <v-chip small :color="gearColor">{{ car.transmission }}</v-chip>
          </div></v-img
        >
      </div>
      <v-card-actions>
        <v-btn text @click="goToCarPage()">
          <v-icon>mdi-alpha-i-circle</v-icon>
          <span>Подробнее</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="reserve()"
          outlined
          color="blue"
          :loading="spinnerBooking"
        >
          <v-icon>mdi-check-bold</v-icon>
          <span>Забронировать</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// import Car from '../views/Car'
import { mapActions, mapMutations, mapGetters } from "vuex";
import cyrillicToTranslit from "cyrillic-to-translit-js";
export default {
  props: {
    car: Object
  },
  data() {
    return {
      spinnerBooking: false
    };
  },
  methods: {
    goToCarPage() {
      // this.dialog = true
      const nameCar = (
        cyrillicToTranslit().transform(this.car.model, "_") + `_${this.car.id}`
      ).toLowerCase();
      this.$router.push({
        name: "car",
        params: { carId: this.car.id, carRoute: nameCar }
      });
    },
    async reserve() {
      // this.updateStep(null)
      if (this.$metrika) this.$metrika.reachGoal("najal zabronirovat");
      if (this.bookingState.step == 404) this.updateStep(null);
      this.spinnerBooking = true;
      // await this.getCarById(this.car.id);
      if (this.car && this.car.filialId)
        await this.findFilialById(this.car.filialId);
      this.updateBookingState(this.car);
      if (this.user.auth && this.user.whatsappIsDefaultChannel) {
        await this.getPriceData();
        this.updateStep(0);
        await this.startBooking();
        // this.calculatePrice();
        this.spinnerBooking = false;
      } else this.spinnerBooking = false;
    },
    // startBooking() {
    //     // console.log(this.car.contactUs);
    //     // this.$router.push(this.car.contactUs)
    //     // window.location = this.car.contactUs
    //     // this.updateBookingState(true)
    // },
    ...mapMutations(["updateBookingState", "updateStep"]),
    ...mapActions([
      "startBooking",
      "getCarById",
      "getPriceData",
      "findFilialById"
      // 'calculatePrice',
    ])
  },
  computed: {
    minPriceText() {
      if (this.car?.currentTariffFull) {
        if (this.car?.currentTariffFull?.[15]?.[0] == 2) {
          return `${this.car.price}`;
        } else {
          return `${this.car.minPrice}`;
        }
      } else {
        return this.car.minPrice;
      }
      //return this.car?.currentTariffFull?.[15]?.[0]==2?`до ${this.car.price}`:`от ${this.car.minPrice}`
    },
    chipColor() {
      if (this.car.status.id == 0) return "green";
      if (this.car.status.id == 1) return "blue";
      if (this.car.status.id == 2) return "orange";
      if (this.car.status.id == 3) return "purple";
      if (this.car.status.id == 4) return "red darken-4";
      return "grey";
    },
    gearColor() {
      if (this.car.transmission == "Автомат") return "#00ACC1";
      if (this.car.transmission == "Механика") return "#00897B";
      return "grey";
    },
    ...mapGetters(["bookingState", "user"])
  },
  components: {
    // Car,
    // Booking
  }
};
</script>
<style>
.korobka {
  position: absolute;
}
.carTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subTitle {
  padding-right: 0px;
}
</style>
